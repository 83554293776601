.text-style-d1 {
  font-size: 32px;
  line-height: 1.2;
}

.text-style-d2 {
  font-size: 24px;
  line-height: 1.2;
}

.text-style-d3 {
  font-size: 18px;
  line-height: 1.2;
}

.text-style-p {
  font-size: 16px;
  line-height: 1.2;
}