/* colors */
.text-primary {
  color: var(--color-primary);
} 

.text-secondary {
  color: var(--color-secondar);
}

.text-background {
  color: var(--color-background);
}

.text-paragraph {
  color: var(--color-text);
}

.text-headings {
  color: var(--color-headings);
}

.text-links {
  color: var(--color-links);
}

.text-links-hover:hover {
  color: var(--color-links-hover);
}

.text-buttons {
  color: var(--color-buttons);
}

.text-buttons-hover:hover {
  color: var(--color-buttons-hover);
}

/* backgrounds */
.bg-primary {
  background-color: var(--color-primary);
} 

.bg-secondary {
  background-color: var(--color-secondar);
}

.bg-background {
  background-color: var(--color-background);
}

.bg-paragraph {
  background-color: var(--color-text);
}

.bg-headings {
  background-color: var(--color-headings);
}

.bg-links {
  background-color: var(--color-links);
}

.bg-links-hover:hover {
  background-color: var(--color-links-hover);
}

.bg-buttons {
  background-color: var(--color-buttons);
}

.bg-buttons-hover:hover {
  background-color: var(--color-buttons-hover);
}