@import "./global/styles.css";
@import "./global/typography.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --c-blue-ammagamma: rgb(0, 71, 92);
  --c-red: rgb(199, 75, 55);
  --c-orange: rgb(240, 129, 22);
  --c-dark-yellow: rgb(210, 158, 14);
  --c-yellow: rgb(235, 185, 10);
  --c-grey: rgb(124, 145, 146);
  --c-pink: rgb(232, 76, 95);
  --c-cream: rgb(254, 251, 242);
  --c-black: rgb(45, 42, 38);
  --c-dark-blue: rgb(0, 60, 80);
  --c-light-blue: rgb(45, 121, 141);
  --c-green: rgb(72, 118, 42);
  --c-green-secondary: rgb(156, 167, 157);
  --c-green-tertiary: rgb(155, 174, 136);
  --c-antique-pink: rgb(144, 98, 95);
  --color-primary: var(--c-blue-ammagamma);
  --color-secondary: var(--c-pink);
  --color-background: var(--c-cream);
  --color-paragraph: var(--c-blue-ammagamma);
  --color-headings: var(--c-blue-ammagamma);
  --color-links: var(--c-blue-ammagamma);
  --color-links-hover: var(--c-blue-ammagamma);
  --color-buttons: var(--c-blue-ammagamma);
  --color-buttons-hover: var(--c-light-blue);
  --easing: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  --animation-time-fast: 0.2s;
  --animation-time: 0.3s;
  --animation-time-slow: 0.6s;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  isolation: isolate;
}

#root {
  height: 100%;
}

html,
body {
  overscroll-behavior: none;
  font-family: "Open Sans", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* ::selection {
  background-color: var(--color-background);
  color: var(--color-text);
} */

a {
  color: var(--color-links);
  transition: color var(--animation-time-fast) var(--easing);
}

a:hover {
  color: var(--color-links-hover);
}

a:focus,
button:focus {
  outline: 0;
}

a:focus-visible,
button:focus-visible {
  outline: 1px;
}

[data-placeholder] {
  animation: load 0.7s infinite;
}
@keyframes load {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

.has-tooltip {
  position: relative;
}

.tooltip {
  visibility: hidden;
  position: absolute;
  transform: translate3d(0, -100%, 0);
  margin: 0 auto;
  cursor: pointer;
  width: 300px;
}
.has-tooltip:hover .tooltip {
  visibility: visible;
  z-index: 100;
  opacity: 1;
}

.MuiInputBase-input:focus {
  box-shadow: none;
}

body .react-calendar__tile:disabled {
  background-color: #f0f0f0;
  opacity: 0.3;
  cursor: auto;
}

body .react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
  opacity: 0.3;
  cursor: auto;
}

body .react-calendar__tile--active:enabled:focus {
  background: none;
  border-radius: 0.5rem;
  color: black;
}

body .react-calendar__navigation__label__labelText--from {
  text-transform: capitalize;
}

body .day-selected {
  background: rgba(0, 71, 92, 0.87) !important;
}

body .active-day-not-selectable {
  cursor: auto !important;
}

body .active-day-not-selectable:hover {
  cursor: auto !important;
  background: none !important;
}

body .react-calendar__tile--active {
  background: none;
  color: black;
}

body .react-calendar__tile--active:enabled:hover {
  background: #fee2e2;
}
body .react-calendar__tile:selected {
  background: #e5e7eb;
}

body .react-calendar__tile--now {
  background: none;
}

body .day-selected abbr {
  color: white;
}

body .react-calendar__tile,
body .react-calendar__navigation__arrow,
body .react-calendar__navigation__label {
  border-radius: 0.5rem;
}

body .react-calendar {
  border: 0;
  background-color: #f3f4f6;
  width: 100%;
}

.point-rating {
  width: 20px;
  height: 20px;
  border: 2px solid var(--c-blue-ammagamma);
  border-radius: 50%;
  display: inline-block;
}

.point-full-rating {
  background: var(--c-blue-ammagamma);
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
}